
.login-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/images/evaluation_back.png");
    background-repeat: no-repeat;
    background-size: cover;

    .main-content {
        height: 100%;
        width: 100%;
        margin: 0 160px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-box {
            margin-top: 90px;
            height: calc(100% - 90px);

            img {
                max-height: 100%;
            }
        }
    }

    .right-box {
        width: 500px;
        height: 580px;
        background: #FFFFFF;
        box-shadow: 0px 0px 18px 3px rgba(18, 30, 87, 0.1);
        border-radius: 8px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .tip-title {
            position: absolute;
            left: 60px;
            top: 100px;
            font-size: 24px;
            color: #060211;
        }

        .center-box {
            margin: 200px 60px 0;
            width: calc(100% - 120px);
        }

        .el-button {
            width: 100%;
            background: #2DC079;
            color: #fff;
            font-size: 16px;
            height: 40px;
            border-radius: 4px;
            margin-top: 66px
        }

        ::v-deep .el-form {
            .el-form-item {
                margin-bottom: 5px;
            }

            .el-form-item__label:before {
                content: '';
            }

            .el-form-item__content {
                margin-left: 0 !important;
                width: 100%;
            }
        }
    }
}
